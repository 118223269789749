body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: mainFont;
  background-color: #f3f3f1;
}

.ant-tabs-nav {
  margin: 0 !important;
}

@font-face {
  font-family: mainFontBold;
  src: url(../public/font/sansheavy.woff2);
}

@font-face {
  font-family: mainFont;
  src: url(../public/font/interlight.woff2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-tabs-tab-btn {
  padding-top: 3px;
  font-weight: 400;
  font-size: 0.78rem;
  color: black;
  user-select: none;
}

.ant-table-cell {
  vertical-align: middle;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 0px !important;
  max-height: 0.6em;
  line-height: 1em;
  white-space: nowrap;
  cursor: pointer;
}

.ant-btn-primary {
  background-color: #1d4ed8 !important;
}

.ant-select-selector {
  height: 38px !important;
}

/* .ant-tabs-tab {
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
} */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.react-flow__attribution {
  display: none !important;
}


.sidebar-box {
  max-height: 600px;
  position: relative;
  overflow: hidden;
}

.sidebar-box .read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 500px 0;

  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, white);
}

@tailwind base;
@tailwind components;
@tailwind utilities;